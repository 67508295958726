<template>
  <div>
    <!--
      TODO Rename translation key to OnfidoSelectDocument.[value]
      Current names suggest page usage
    -->
    <h1 class="type-h1 mb-2">{{ $t('PageOnfido.Header').value }}</h1>
    <p class="type-subtitle mb-6">{{ $t('PageOnfido.Description').value }}</p>

    <AppList>
      <XeListItem
        v-for="(document, idx) in allowedDocuments"
        :key="idx"
        :icon="document.icon"
        :analytics-name="'onfido-document-' + document.value"
        :text="document.text"
        @select="() => selectDocument(document.value)"
      >
        <template #figure-icon>
          <AppIcon name=" ">
            <Component :is="document.icon"></Component>
          </AppIcon>
        </template>
      </XeListItem>
    </AppList>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import XeListItem from '@galileo/components/XeListItem/XeListItem'

import {
  IconGlobe,
  IconTaxi,
  IconUserIdentification,
  IconIdentification,
} from '@oen.web.vue2/icons'

import { AppList, AppFigure, AppIcon, useMediaQuery } from '@oen.web.vue2/ui'

export default {
  name: 'Onfido',
  components: {
    AppList,
    AppFigure,
    AppIcon,
    IconGlobe,
    IconTaxi,
    IconUserIdentification,
    IconIdentification,
    XeListItem,
  },
  emits: ['select'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    const settingsDocuments = 'PASSPORT,NATIONAL_IDENTITY_CARD,RESIDENCE_PERMIT,DRIVING_LICENSE' // in XE the documents are fixed
    const documents = settingsDocuments.replace(/\s/g, '').toLowerCase().split(',')
    const allowedDocuments = documents.map((document) => {
      if (document === 'driving_license') {
        // Onfido expects driving_licence !
        document = 'driving_licence'
      }
      let text = document
      let icon = 'IconIdentification'
      if (text === 'passport') {
        text = $t('PageOnfido.DocumentPassport').value
        icon = 'IconGlobe'
      } else if (text === 'driving_licence') {
        text = $t('PageOnfido.DocumentDrivingLicense').value
        icon = 'IconTaxi'
      } else if (text === 'national_identity_card') {
        text = $t('PageOnfido.DocumentNationalIdCard').value
        icon = 'IconUserIdentification'
      } else if (text === 'residence_permit') {
        text = $t('PageOnfido.DocumentResidencePermit').value
        icon = 'IconIdentification'
      }

      return {
        value: document,
        text: text,
        icon: icon,
      }
    })

    const selectDocument = async (document) => {
      emit('select', document)
    }

    return {
      mq: useMediaQuery(),
      allowedDocuments,
      selectDocument,
      $t,
    }
  },
}
</script>

<style scoped></style>
