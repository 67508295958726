<template>
  <InformationAppModal
    :icon="getIconName()"
    :shouldDisplayCloseButton="false"
    @close="() => null"
    :value="true"
  >
    <h1 class="modal-title">
      {{ $t(`VerificationOutcomeModal.${props.outcome}.Title`).value }}
    </h1>
    <p>
      {{ $t(`VerificationOutcomeModal.${props.outcome}.Text`).value }}
    </p>
    <template #footer>
      <AppButton :loading="logoutLoading" @click="primaryButtonCallback" class="mb-6">{{
        $t(`VerificationOutcomeModal.${props.outcome}.PrimaryButton`).value
      }}</AppButton>
      <AppButton v-if="showSecondaryButton" theme="secondary" @click="secondaryButtonCallback">{{
        $t(`VerificationOutcomeModal.${props.outcome}.SecondaryButton`).value
      }}</AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import { ref } from '@vue/composition-api'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { AppButton, AppLink } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'
import { VERIFICATION_OUTCOMES } from '@galileo/constants/Verification.const'
import { onSuccessfulVerification } from '@galileo/composables/useVerifyUser'
import { useAppStore, useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'VerificationOutcomeModal',
  components: {
    InformationAppModal,
    AppButton,
    AppLink,
    ICON_TYPE,
  },
  props: {
    isOpen: { default: false },
    outcome: { required: true }, // VERIFICATION_OUTCOMES
  },

  emits: ['closeModal'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()

    const logoutLoading = ref(false)

    const primaryButtonCallback = async () => {
      if (props.outcome === VERIFICATION_OUTCOMES.Closed) {
        logoutLoading.value = true
        // replace with pinia call
        await useAuthStore().logout(true)
      } else if (
        props.outcome === VERIFICATION_OUTCOMES.Rejected ||
        props.outcome === VERIFICATION_OUTCOMES.Pending
      ) {
        router.replace('/activity')
      } else if (props.outcome === VERIFICATION_OUTCOMES.Success) {
        onSuccessfulVerification(router.replace)
      } else {
        router.replace('/send-money/summary')
      }
      emit('closeModal')
    }

    const secondaryButtonCallback = () => {
      if (
        props.outcome === VERIFICATION_OUTCOMES.Rejected ||
        props.outcome === VERIFICATION_OUTCOMES.Closed
      ) {
        useAppStore().openHelpDesk({
          loadingRef: ref(false),
        })
      } else if (props.outcome === VERIFICATION_OUTCOMES.PaymentAlternativeMethodAvailable) {
        router.replace('/send-money/summary')
      } else {
        router.replace('/activity')
      }
      emit('closeModal')
    }

    const getIconName = () => {
      switch (props.outcome) {
        case VERIFICATION_OUTCOMES.PaymentAlternativeMethodAvailable:
          return 'creditCard'
        case VERIFICATION_OUTCOMES.Rejected:
        case VERIFICATION_OUTCOMES.PaymentNoAlternativeMethods:
          return 'crossmark'
        case VERIFICATION_OUTCOMES.Closed:
          return 'lock'
        case VERIFICATION_OUTCOMES.Pending:
          return 'hourglass'
        case VERIFICATION_OUTCOMES.Success:
          return 'checkmark'
        default:
          return 'warningYellow'
      }
    }

    const showSecondaryButton =
      props.outcome !== VERIFICATION_OUTCOMES.Success &&
      props.outcome !== VERIFICATION_OUTCOMES.Pending

    return {
      ICON_TYPE,
      $t,
      primaryButtonCallback,
      secondaryButtonCallback,
      logoutLoading,
      props,
      getIconName,
      VERIFICATION_OUTCOMES,
      showSecondaryButton,
    }

    // DO NOT REMOVE: Translation keys for localisation
    // __
    // $t('VerificationOutcomeModal.ResubFaceNotDetected.Title')
    // $t('VerificationOutcomeModal.ResubFaceNotDetected.Text')
    // $t('VerificationOutcomeModal.ResubFaceNotDetected.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubFaceNotDetected.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubUnsupportedType.Title')
    // $t('VerificationOutcomeModal.ResubUnsupportedType.Text')
    // $t('VerificationOutcomeModal.ResubUnsupportedType.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubUnsupportedType.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubPoorQuality.Title')
    // $t('VerificationOutcomeModal.ResubPoorQuality.Text')
    // $t('VerificationOutcomeModal.ResubPoorQuality.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubPoorQuality.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubDocumentFrontMissing.Title')
    // $t('VerificationOutcomeModal.ResubDocumentFrontMissing.Text')
    // $t('VerificationOutcomeModal.ResubDocumentFrontMissing.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubDocumentFrontMissing.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubDocumentBackMissing.Title')
    // $t('VerificationOutcomeModal.ResubDocumentBackMissing.Text')
    // $t('VerificationOutcomeModal.ResubDocumentBackMissing.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubDocumentBackMissing.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubDocumentFrontNotFullyInFrame.Title')
    // $t('VerificationOutcomeModal.ResubDocumentFrontNotFullyInFrame.Text')
    // $t('VerificationOutcomeModal.ResubDocumentFrontNotFullyInFrame.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubDocumentFrontNotFullyInFrame.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubDocumentBackNotFullyInFrame.Title')
    // $t('VerificationOutcomeModal.ResubDocumentBackNotFullyInFrame.Text')
    // $t('VerificationOutcomeModal.ResubDocumentBackNotFullyInFrame.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubDocumentBackNotFullyInFrame.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubPoorIDQuality.Title')
    // $t('VerificationOutcomeModal.ResubPoorIDQuality.Text')
    // $t('VerificationOutcomeModal.ResubPoorIDQuality.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubPoorIDQuality.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubIDExpired.Title')
    // $t('VerificationOutcomeModal.ResubIDExpired.Text')
    // $t('VerificationOutcomeModal.ResubIDExpired.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubIDExpired.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubIDDamaged.Title')
    // $t('VerificationOutcomeModal.ResubIDDamaged.Text')
    // $t('VerificationOutcomeModal.ResubIDDamaged.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubIDDamaged.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubMissingInformation.Title')
    // $t('VerificationOutcomeModal.ResubMissingInformation.Text')
    // $t('VerificationOutcomeModal.ResubMissingInformation.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubMissingInformation.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.ResubUnknownError.Title')
    // $t('VerificationOutcomeModal.ResubUnknownError.Text')
    // $t('VerificationOutcomeModal.ResubUnknownError.PrimaryButton')
    // $t('VerificationOutcomeModal.ResubUnknownError.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.Pending.Title')
    // $t('VerificationOutcomeModal.Pending.Text')
    // $t('VerificationOutcomeModal.Pending.PrimaryButton')
    // __
    // $t('VerificationOutcomeModal.Success.Title')
    // $t('VerificationOutcomeModal.Success.Text')
    // $t('VerificationOutcomeModal.Success.PrimaryButton')
    // __
    // $t('VerificationOutcomeModal.Rejected.Title')
    // $t('VerificationOutcomeModal.Rejected.Text')
    // $t('VerificationOutcomeModal.Rejected.PrimaryButton')
    // $t('VerificationOutcomeModal.Rejected.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.Closed.Title') / $t('AccountRestricted.UnableToSendMoneyTitle').value
    // $t('VerificationOutcomeModal.Closed.Text') / $t('AccountRestricted.TransactionsAreNotAvailableParagraph').value
    // $t('VerificationOutcomeModal.Closed.PrimaryButton') / $t('AccountRestricted.CancelButtonText').value
    // $t('VerificationOutcomeModal.Closed.SecondaryButton') / $t('AccountRestricted.VisitHelpCenterButtonText').value
    // __
    // $t('VerificationOutcomeModal.PaymentAlternativeMethodAvailable.Title')
    // $t('VerificationOutcomeModal.PaymentAlternativeMethodAvailable.Text')
    // $t('VerificationOutcomeModal.PaymentAlternativeMethodAvailable.PrimaryButton')
    // $t('VerificationOutcomeModal.PaymentAlternativeMethodAvailable.SecondaryButton')
    // __
    // $t('VerificationOutcomeModal.PaymentNoAlternativeMethods.Title')
    // $t('VerificationOutcomeModal.PaymentNoAlternativeMethods.Text')
    // $t('VerificationOutcomeModal.PaymentNoAlternativeMethods.PrimaryButton')
    // $t('VerificationOutcomeModal.PaymentNoAlternativeMethods.SecondaryButton')
  },
}
</script>
<style scoped>
::v-deep .card-header {
  @apply px-12;
}
::v-deep .card-header .card-header-right {
  @apply self-start;
}
::v-deep .card-footer {
  @apply items-center;
}
.modal-title {
  @apply text-3xl font-semibold;
}
.modal-text {
  @apply text-sm font-normal tracking-normal text-center;
}
::v-deep .text-underline {
  @apply underline;
}
::v-deep .link {
  @apply text-base font-bold;
  max-width: fit-content;
}
::v-deep .modal-body > p {
  @apply mt-3;
}
::v-deep .card-content .card-content-block {
  @apply mb-6;
}
::v-deep .card-header .card-header-title {
  @apply h-24 flex items-center;
}
::v-deep .card-header-title svg {
  @apply w-16 h-16;
}
</style>
