// Funcitons used by both the onfido and the veriff processess should be put in here

import { Ref } from '@vue/composition-api'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { VerificationOutcomes, VERIFICATION_OUTCOMES } from '@galileo/constants/Verification.const'
import { TRANSFER } from '@galileo/constants/sendMoneyFlow.const'
import {
  useAnalyticsStore,
  useAppStore,
  useAuthStore,
  useSendMoneyStore,
  useStoredOrderStore,
} from '@galileo/stores'

const pause = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration))

export const checkIfUserVerified = async (
  setIsPolling: (newValue: boolean) => void,
  checkAlreadyStarted: Ref<boolean>,
  setVerificationOutcome: (outcome: VerificationOutcomes | null) => void,
  onfidoData: any = null
) => {
  if (checkAlreadyStarted.value) {
    return
  }

  checkAlreadyStarted.value = true

  useAuthStore().setPendingVerifyUser()

  let attemptCount = 0
  let verificationOutcome = VERIFICATION_OUTCOMES.Pending

  try {
    setIsPolling(true)
    while (attemptCount < 10) {
      verificationOutcome = await useSendMoneyStore().verifyIdentity(onfidoData)
      attemptCount++
      if (verificationOutcome !== VERIFICATION_OUTCOMES.Pending) break
      if (attemptCount === 10) break
      await pause(30000)
    }

    setIsPolling(false)

    if (verificationOutcome === VERIFICATION_OUTCOMES.Pending) {
      useAnalyticsStore().track({
        event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_COMPLETED,
        traits: {
          biometricPlatform: 'Veriff',
          externalSessionId: useSendMoneyStore().verificationApplicationId,
          biometricStatus: TRANSFER.RESPONSE.VERIFIED.PENDING,
          location: 'sendMoney',
        },
      })
    }

    if (verificationOutcome === VERIFICATION_OUTCOMES.Rejected) {
      useSendMoneyStore().setAccountIsRestricted(true)
    }
    if (
      verificationOutcome === VERIFICATION_OUTCOMES.Success ||
      verificationOutcome === VERIFICATION_OUTCOMES.EDDRequired
    ) {
      useAuthStore().pendingVerifyUser = null
    }

    setVerificationOutcome(VERIFICATION_OUTCOMES[verificationOutcome])
  } catch (ex) {
    setIsPolling(false)
    useAppStore().logException('Exception during sending to Onfido', ex)
    setVerificationOutcome(VERIFICATION_OUTCOMES.ResubUnknownError)
  }
}

export const onSuccessfulVerification = async (replaceRoute: Function) => {
  useStoredOrderStore().storedOrder = null
  const transferId = useSendMoneyStore().form.transferId
  let nextPage = await useSendMoneyStore().processOrderSteps(transferId)
  replaceRoute(nextPage)
}
