<template>
  <div>
    <div id="onfido-mount" data-private></div>

    <!-- Polling in progress  -->
    <InformationAppModal
      :shouldDisplayCloseButton="false"
      @close="() => null"
      :value="pollingOnfido"
      class="polling-modal"
      no-icon
    >
      <div title="Processing" class="overlapping">
        <IconHourglassGalileo />
        <AppSpinnerBig class="onfido-spinner m-0" :loading="true" :inline="true" />
      </div>
      <h1 class="modal-title">
        {{ $t('PageOnfido.VerifyingTitle').value }}
      </h1>
      <p>
        {{ $t('PageOnfido.VerifyingText').value }}
      </p>
      <template #footer>
        <div></div>
      </template>
    </InformationAppModal>

    <VerificationOutcomeModal v-if="verificationOutcome" :outcome="verificationOutcome" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { init } from 'onfido-sdk-ui'
import { useRouter } from '@galileo/composables/useRouter'
import { KYC_STATUS } from '@galileo/models/Cst/appVariables'
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import XeCardImageTitle from '@galileo/components/XeCardImageTitle/XeCardImageTitle'
import { IconHourglassGalileo } from '@oen.web.vue2/icons'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'
import {
  useAnalyticsStore,
  useAppStore,
  useI18nStore,
  useThemeStore,
  useAuthStore,
  useSendMoneyStore,
} from '@galileo/stores'
import { checkIfUserVerified } from '@galileo/composables/useVerifyUser'
import { VERIFICATION_OUTCOMES } from '@galileo/constants/Verification.const'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import VerificationOutcomeModal from '@galileo/components/VerificationOutcomeModal/VerificationOutcomeModal.vue'

import {
  useMediaQuery,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  AppButton,
  AppBackButton,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

export default {
  name: 'Onfido',
  components: {
    AppModalFooter,
    AppModal,
    AppModalHeader,
    AppButton,
    AppBackButton,
    XeCardImageTitle,
    AppSpinnerBig,
    InformationAppModal,
    IconHourglassGalileo,
    VERIFICATION_OUTCOMES,
    VerificationOutcomeModal,
  },
  emits: ['init', 'loaded', 'successful', 'tryAgain'],
  setup(props, { emit }) {
    const i18nStore = useI18nStore()
    const { $t } = i18nStore
    const authStore = useAuthStore()
    const router = useRouter()
    const themeStore = useThemeStore()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()

    const pollingOnfido = ref(false)
    const setIsPolling = (newValue) => (pollingOnfido.value = newValue)

    const checkAlreadyStarted = ref(false)

    const loadingContactUs = ref(false)

    const verificationOutcome = ref(null)

    const setVerificationOutcome = (status) =>
      (verificationOutcome.value = VERIFICATION_OUTCOMES[status])

    const verificationPlatform = sendMoneyStore.verificationPlatform

    const showStatus = (kycStatus) => {
      switch (kycStatus) {
        case KYC_STATUS.KYC_SUCCESS:
          return true
        case KYC_STATUS.KYC_SUSPECTED:
          sendMoneyStore.setAccountIsRestricted()
          authStore.getUserAction(authStore.user.customer.id)
          router.push({ name: STEPS.SEND_MONEY_FAILED })
          return true
        case KYC_STATUS.KYC_IN_PROGRESS:
        default:
          return false
      }
    }

    let portalNodeObserver = null
    const createOnfidoPortalObserver = (portalNode) => {
      // Disconnect onfido portal observer if an instance already exists
      // Frees this observer to be reused
      if (portalNodeObserver) {
        portalNodeObserver.disconnect()
      }

      // Create an observer for onfido portal dom changes
      portalNodeObserver = new MutationObserver(() => {
        /*
         * When any dom mutation occurs query portal node for all img and video elements
         * This event is fired when dom elements are removed/added.
         * The omitted mutations parameter, returns a collection of changed elements
         */
        const childNodes = portalNode.querySelectorAll('img, video')
        if (childNodes.length > 0) {
          /*
           * If there are any img or video elements set attribute data-private
           * Excluded data is never sent to LogRocket servers.
           * Data can be excluded using a data attribute of data-private any element:
           * Source: https://docs.logrocket.com/docs/privacy
           */
          childNodes.forEach((childNode) => {
            childNode.setAttribute('data-private', '')
          })
        }
      })

      portalNodeObserver.observe(portalNode, { subtree: true, childList: true })
    }

    // Create a mutation observer for document.body
    // Mutation observer will only look at direct children
    // onfido-sdk-ui-Theme-portal is appended to bottom of document.body
    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          // Create a new mutation observer for onfido portal modal
          // and stop observing document.body direct child dom changes
          if (node.className === 'onfido-sdk-ui-Theme-portal') {
            createOnfidoPortalObserver(node)
            observer.disconnect()
          }
        })
      })
    })
    observer.observe(document.body, { subtree: false, childList: true })

    const onCompleteActionStarted = ref(false)
    const selectDocument = async (document) => {
      let token
      try {
        token = sendMoneyStore.verificationToken
      } catch (ex) {
        appStore.logException('Exception during getting Onfido token', ex)
        appStore.messageBoxGenericError()
        emit('loaded', false)
        return
      }
      emit('loaded', true)

      const documentTypes = {}
      documentTypes[document] = true

      const onFidoOnCompleteHandler = async (res) => {
        if (onCompleteActionStarted.value) {
          return
        }
        analyticsStore.track({
          event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_SUBMITTED,
          traits: {
            biometricPlatform: 'Onfido',
            location: 'sendMoney',
          },
        })

        onCompleteActionStarted.value = true

        pollingOnfido.value = true
        onfido.setOptions({ isModalOpen: false })
        await checkIfUserVerified(setIsPolling, checkAlreadyStarted, setVerificationOutcome, res)
      }

      const onfido = init({
        customUI: { ...themeStore.getOnfidoTheme },
        token: token,

        onComplete: async (res) => await onFidoOnCompleteHandler(res),
        onModalRequestClose: () => {
          // Update options with the state of the modal
          onfido.setOptions({ isModalOpen: false })
        },
        onError: (err) => {
          if (err?.message?.startsWith('Camera required')) {
            appStore.logInfo('Onfido plugin: ' + err.message)
          } else if (!pollingOnfido.value) {
            appStore.logException('Exception during Onfido plugin', err)
          }
        },
        onUserExit: function (userExitCode) {
          // __SEGMENT__EVENT
          analyticsStore.track({
            event: SEGMENT_EVENTS.BIOMETRIC_VERIFICATION_EXITED,
            traits: {
              location: 'sendMoney',
              biometricPlatform: verificationPlatform,
            },
          })
        },
        useMemoryHistory: true,
        useModal: true,
        isModalOpen: true,
        language: i18nStore.languageTagLanguage(i18nStore.i18n.locale),
        steps: [
          {
            type: 'document',
            options: {
              documentTypes: documentTypes,
              forceCrossDevice: true,
            },
          },
          {
            type: 'face',
            options: {
              requestedVariant: 'photo',
              uploadFallback: true,
              forceCrossDevice: true,
            },
          },
        ],
      })
    }

    const tryAgain = () => {
      emit('tryAgain')
    }
    const contactUs = () => {
      appStore.openHelpDesk({ loadingRef: loadingContactUs })
    }
    const pendingOk = () => {
      router.replace('/activity')
    }
    const done = () => {
      emit('successful')
    }

    emit('init', {
      selectDocument,
      showStatus,
    })

    const goToActivityPage = () => {
      router.push('/Activity')
    }

    return {
      verificationOutcome,
      mq: useMediaQuery(),
      pollingOnfido,
      loadingContactUs,
      selectDocument,
      tryAgain,
      contactUs,
      pendingOk,
      done,
      goToActivityPage,
      $t,
    }
  },
}
</script>

<style scoped>
.overlapping svg {
  position: absolute;
}
::v-deep .overlapping .loading-spinner .loading-spinner-container {
  margin: 10px;
}
::v-deep .overlapping .loading-spinner .loading-spinner-container svg {
  height: 80px;
  width: 80px;
}

::v-deep .polling-modal .card-content-block {
  margin: 0px;
}
::v-deep .polling-modal .card-header {
  display: none;
}

.overlapping {
  display: flex;
  justify-content: center;
  align-items: center;
  position: rleative;
  margin: 30px 0px 0px 0px;
}
</style>
